var nl = {
    fullName: "Nederlands",
    title: "LOCI",
    panels: {
        home: "Home",
        assignments: "Opdrachten",
        templates: "Templates",
        users: "Gebruikers",
        calendar: "Kalender",
        createAssignment: "Nieuwe opdracht",
        editAssignment: "Opdracht bewerken",
        terms: "Bepalingen",
        profile: "Profiel",
        company: "Mijn bedrijf",
        credits: "Credits"
    },
    assignments: {
        info: "Informatie over de opdracht",
        addDocument: "Voeg plaatsbeschrijving toe",
        new: "Nieuwe opdracht",
        create: "Aanmaken",
        update: "Bijwerken",
        name: "Naam van de opdracht",
        planDate: "Geplande datum",
        status: {
            label: "Status",
            PENDING: "Onafgewerkt",
            DONE: "Afgewerkt"
        },
        executionDate: "Uitgevoerd op",
        assignee: "Toegewezen aan",
        collaborators: "Medewerkers",
        assign: "Toewijzen",
        unassigned: "Niet toegewezen",
        markPending: "Markeer als onafgewerkt",
        markDone: "Markeer als afgewerkt",
        attributes: {
            name: "Naam van de opdracht",
            address: {
                address: "Adres",
                city: "Stad / Plaats",
                zipCode: "Postcode",
                street: "Straat",
                number: "Huisnummer",
                bus: "Bus"
            },
            planDate: "Geplande datum",
            executionDate: "Uitgevoerd op",
            assignee: "Toegewezen aan",
            requiredDocuments: "Plaatsbeschrijvingen",
            party: "Partij",
            partyName: "Naam partij",
            partyRole: "Rol",
            partyRolePlaceholder: "Bv. Huurder, eigenaar...",
            partyRolePlaceholder2: "Bv. Verhuurder, bouwheer",
            representative: "Vertegenwoordiger",
            partyInfo: "Extra informatie"
        },
        mail: {
            title: "Document verzenden",
            to: "Naar",
            from: "Van",
            subject: "Onderwerp",
            message: "Boodschap",
            send: "Verzenden"
        },
        filterMine: "Filter toegewezen aan mij",
        noAssignments: "Geen opdrachten beschikbaar",
        assignmentMessage: "Wil u een bericht sturen naar de uitvoerder?",
        addParty: "Partij toevoegen",
        showArchive: "Toon archief",
        showActive: "Toon actieve opdrachten",
        archive: "Archiveren",
        unarchive: "Dearchiveren"
    },
    document: {
        title: "Documenten",
        notAvailable: "Geen documenten beschikbaar",
        downloadPDF: "Download PDF",
        downloadDocx: "Download Docx",
        downloadImages: "Download afbeeldingen als ZIP",
        downloadLastSavedPDF: "Download laatst opgeslagen PDF",
        downloadLastSavedDocx: "Download laatst opgeslagen Docx",
        recencyWarning: "Dit document bevat mogelijks niet de laatste wijzigingen. Hergenereer het document om de laatste wijzigingen door te voeren.",
        generating: "Bezig met genereren",
        downloadingImages: "Downloaden van afbeeldingen",
        generatingDocx: "Docx document genereren",
        generatingPdf: "Docx converteren naar PDF",
        name: "Naam van het document",
        language: "Taal",
        template: "Template",
        nullTemplate: "Geen template gebruiken",
        locationDescription: "Locatieve plaatsbeschrijving",
        reference: "Dossiernummer",
        generate: "Genereer documenten",
        intro: "Intrede",
        executedBy: "Uitgevoerd door",
        executedOn: "Uitgevoerd op",
        sign: "Ondertekenen",
        lock: "Vastzetten",
        exportDocx: "Exporteer als .docx (Word)",
        openDocx: "Openen als .docx (Word)",
        roles: {
            renter: "Huurder",
            rentee: "Verhuurder",
            executor: "Uitvoerder"
        },
        page: "Pagina",
        of: "van",
        lockConfirmationMessage: "Weet je zeker dat je het document wil vastzetten? Dit is definitief en kan niet ongedaan gemaakt worden.",
        previewFormattingMessage: "Preview van het rapport. De formattering zal nog wijzigen wanneer het document wordt gegenereerd.",
        terms: {
            name: "Naam",
            noTermsPlaceholder: "Je hebt nog geen algemene bepalingen gemaakt",
            noName: "Bepaling zonder naam",
            create: "Druk hier om algemene bepaling op te stellen",
            placeholder: "Verhuur NL",
            addParagraph: "Paragraaf toevoegen",
            edit: "Bepalingen bewerken",
            general: "Algemene voorwaarden",
            clauses: "Clausules",
            clauseCostParty: "Extra clausule wanneer kosten betaald worden door één partij",
            clauseCostSplit: "Extra clausule wanneer kosten worden gedeeld",
            remarks: "Algemene opmerkingen voor het rapport",
            signatureTextHeader: "Inleidende tekst bij verklaren van de handtekeningen",
            signatureTextItems: "Elementen weer te geven bij handtekeningen",
            regenerate: "Hergenereren",
            regenerateConfirmationMessage: "Wil je de bepalingen hergenereren op basis van volgende template? Alle wijzigingen gemaakt aan deze bepalingen gaan verloren."
        },
        images: "Afbeeldingen",
        noImages: "Geen afbeeldingen gemaakt"
    },
    generic: {
        pickLanguage: "Kies uw taal",
        delete: "Verwijder",
        add: "Toevoegen",
        new: "Nieuw",
        edit: "Bewerken",
        save: "Opslaan",
        open: "Openen",
        cancel: "Annuleren",
        confirm: "Bevestigen",
        confirmDeletion: "Weet je zeker dat je dit item wil verwijderen?",
        name: "Naam",
        nameNL: "Nederlandse naam",
        nameFR: "Franse naam",
        loading: "Laden...",
        synchronize: "Synchroniseer",
        undo: "Undo",
        download: "Downloaden",
        search: "Zoeken",
        showMore: "Toon meer",
        network: {
            disconnected: "Geen internet"
        },
        updates: {
            yourVersion: "Uw versie",
            latestVersion: "Nieuwste versie",
            updateAvailable: "Een nieuwe versie van de applicatie is beschikbaar.",
        },
        manageApp: "Applicatie beheren",
        loginToCompany: "Inloggen in de omgeving van dit bedrijf",
        loginToCompanyInfo: "Wanneer u in de omgeving van dit bedrijf inlogt, zal u worden uitgelogd. Door terug in te loggen, komt u terecht in de omgeving van dit bedrijf.",
        betaWarning: "LOCI is nu in Bèta. Indien u problemen ervaart, of een vraag heeft, kunt u contact opnemen met aron.geerts@agits.be",
        noName: "Geen naam",
    },
    users: {
        unknown: "Onbekende gebruiker",
        firstName: "Voornaam",
        lastName: "Naam",
        email: "Email",
        emailExists: "E-mailadres is reeds in gebruik",
        password: "Wachtwoord",
        confirmPassword: "WachtwoordBevestigen",
        role: "Rol",
        roles: {
            APP_ADMIN: "Applicatiebeheerder",
            ADMIN: "Beheerder",
            USER: "Gebruiker",
            ON_HOLD: "On hold"
        },
        login: "Aanmelden",
        loginFailed: "Ongeldige gebruikersnaam of wachtwoord",
        forgotPassword: "Wachtwoord vergeten",
        requestPasswordReset: "Wachtwoordreset aanvragen",
        askReset: "Reset aanvragen",
        resetSuccess: "Wachtwoordreset succesvol",
        resetSuccessDescription: "Uw wachtwoord is succesvol gereset.",
        verificationCode: "Verificatie code",
        goSignin: "Naar login",
        passwordSent: "Wachtwoordreset e-mail succesvol verzonden.",
        errorReset: "Er is een onbekende fout opgetreden tijdens de bevestiging van de wachtwoordreset.",
        logout: "Afmelden",
        create: "Nieuwe gebruiker",
        changePassword: "Wachtwoord wijzigen",
        oldPassword: "Oud wachtwoord",
        newPassword: "Nieuw wachtwoord",
        invalidPasswordFormat: "Ongeldig wachtwoord: Kies minstens 8 karakters",
        signature: "Handtekening",
        sign: "Tekenen",
        editSignature: "Handtekening bewerken"
    },
    templates: {
        title: "Templates",
        add: "Toevoegen",
        icon: "Icoon",
        evaluation: "Evaluatie",
        state: "Algemene staat",
        multimedia: "Multimedia",
        observation: "Vaststelling",
        addTemplate: "Template toevoegen",
        addLevel: "Item toevoegen",
        copyFrom: "Kopiëren van",
        noCopy: "Niet kopiëren",
        nl: "Naam NL",
        fr: "Naam FR",
        requiredEvaluations: "Gevraagde gegevens",
        evaluationRequired: "Evaluatie",
        stateRequired: "Algemene staat",
        multimediaRequired: "Multimedia",
        observationRequired: "Vaststelling",
        editRoom: "Item bewerken",
        moveUp: "Naar boven verplaatsen",
        moveDown: "Naar beneden verplaatsen",
        rooms: "Items",
        noRooms: "Geen items"
    },
    inspection: {
        start: "Inspectie starten",
        takePicture: "Foto toevoegen",
        copy: "Kopieer",
        copyStructure: "Kopieer structuur",
        copyStructureWithAttributes: "Kopieer structuur met attributen",
        continue: "Opvolgen",
        generatePDF: "Genereer PDF",
        edit: "Inspectie bewerken",
        general: "Algemeen",
        copySuffix: "opvolging",
        addAttribute: "Attribuut toevoegen",
        attributeName: "Attribuut",
        attributeValue: "Waarde",
        imageDescription: "Beschrijving",
        costingSystem: "Verdeling kosten plaatsbeschrijving",
        splitCosts: "Gedeeld",
        objectType: "Type van het vastgoed",
        moveNode: "Item verplaatsen",
        failedToSave: "De PLB kon niet worden opgeslagen. Mogelijks is deze te groot.",
        inspectionType: {
            label: "Type inspectie",
            OTHER: "Andere",
            BEFORE_WORK: "Voor aanvang werken",
            AFTER_WORK: "Na werken",
            BEFORE_RENT: "Voor huur",
            AFTER_RENT: "Na huur"
        },
        state: {
            label: "Algemene staat",
            NEW: "Nieuw",
            VERY_BAD: "Zeer slecht",
            BAD: "Slecht",
            DECENT: "Degelijk",
            GOOD: "Goed",
            VERY_GOOD: "Zeer goed"
        },
        observation: "Vaststellling",
        observations: "Vaststellingen",
        addObservation: "Vaststelling toevoegen",
        observationType: "Type",
        observationImages: "Afbeeldingen vaststelling",
        remarks: "Opmerkingen",
        addRemark: "Opmerking toevoegen",
        generalImages: "Algemene afbeeldingen",
        overviewImage: "Overzichtsafbeelding",
        addImages: "Afbeeldingen toevoegen",
        addRoom: "Item toevoegen",
        rotateRight: "Rechts draaien",
        rotateLeft: "Links draaien",
        errors: {
            insufficient_credits: "Onvoldoende credits",
            imagesNotLoaded: "Niet alle afbeeldingen werden geladen. Controleer of de opdracht correct is gesynchroniseerd."
        },
        checkInspectionPrompt: "Geef de postcode van de plaatsbeschrijving in"
    },
    company: {
        name: "Bedrijfsnaam",
        email: "Email",
        address1: "Adresregel 1",
        address2: "Adresregel 2",
        phone: "Telefoonnummer",
        vat: "BTW",
        web: "Web",
        uploadLogo: "Klik hier om een afbeelding of logo van uw bedrijf te uploaden",
        registrationTimestamp: "Geregistreerd op",
        subscriptionType: "Abonnement",
        registration: {
            errors: {
                length: function (minLength, maxLength) { return "Geef een waarde in van ".concat(minLength, " tot ").concat(maxLength, " karakters."); },
                email: "Geef een geldig e-maildres in",
                phone: "Geef een geldig telefoonnummer in",
                vat: "Geef een geldig BTW-nummer op. Formaat: BE0999999999, NL123456789B01 of FR12345678901",
                invalidCode: "Ongeldige registratiecode",
            },
            seeCompanyEmail: "Uw bedrijf werd met succes geregistreerd. Bevestig uw bedrijfsemail via de link in de email die we je hebben gestuurd",
            companySuccessful: "Uw bedrijfsemail werd bevestigd. Creëer een gebruiker om van start te gaan.",
            seeUserEmail: "Bedankt voor uw registratie! Bevestig uw e-mailadres via de link in je email.",
            resendEmail: "Email opnieuw versturen",
            resendEmailMessage: "Indien u de email niet ontvangen heeft, check uw spam of neem contact op met aron.geerts@agits.be"
        }
    },
    portal: {
        downloadApp: "Download de app",
        documentation: "Handleiding",
        pricing: "Tarieven",
        free: "Gratis",
        pro: "Professioneel",
        intro: [
            "Snel, efficiënt en volledig plaatsbeschrijvingen opmaken?\nStart nu je gratis proefperiode of neem meteen een abonnement.",
            "Met de mobiele applicatie kan jouw team plaatsbeschrijvingen ter plaatse opmaken en automatisch rapporten genereren.\nVervolgens kan je deze delen binnen je team en met je klanten.",
            "De applicatie is beschikbaar in het Frans, Engels en Nederlands"
        ],
        signup: "Registreren",
        readAndAccepted: "Door te registeren ga je akkoord met de",
        termsAndConditions: "Algemene voorwaarden",
        pricingPerItem: function (price) { return "\u20AC".concat(price, " per plaatsbeschrijving"); },
        freeTrialFeatures: [
            "1 maand gratis proefperiode",
            "4 gratis digitale plaatsbeschrijvingen"
        ],
        excludedFreeTrialFeatures: [
            "Ongelimiteerd aantal gebruikers",
        ],
        professionalFeatures: [
            "Digitaal plaatsbeschrijvingen opmaken",
            "Ongelimiteerd aantal gebruikers",
            "Onbeperkte opslag van uw plaatsbeschrijvingen in de cloud"
        ],
        freeTrial: "Gratis proefperiode",
        startUpFee: function (price) { return "\u20AC".concat(price, " opstartkost"); },
        tryNow: "Probeer het",
        contact: {
            title: "Contact",
            email: "Uw e-mailadres",
            message: "Bericht",
            send: "Verzenden"
        },
        contactReceived: "Bedankt voor uw bericht. We contacteren u zo snel mogelijk."
    },
    credits: {
        title: "Credits",
        yourBalance: "Uw huidig saldo",
        yourNewBalance: "Uw nieuw saldo",
        transactions: "Transacties",
        buyConfirmation: "Het aanmaken van deze plaatsbeschrijving resulteert in een vermindering van uw saldo. Weet u zeker dat u wilt doorgaan?",
        amount: "Aantal",
        price: "Prijs",
        credits: "Credits",
        buyCredits: "Koop credits",
        excludingVAT: "Excl. BTW",
        successfulTransaction: "Order geplaatst! U ontvangt zodra een email op uw bedrijfs-emailadres. Uw saldo wordt binnen enkele ogenblikken aangepast!",
        failedTransaction: "Er ging iets mis bij het verwerken van de betaling. Geen zorgen, er werd geen bedrag aangerekend.",
        transactionType: {
            label: "Type",
            USE: "Aankoop plaatsbeschrijving",
            PURCHASE: "Betaling",
            REGISTRATION: "Registratie"
        },
        freeTrialExpired: "Uw gratis proefperiode is vervallen",
        freeTrialExpiring: function (nbDays) { return "Uw gratis proefperiode vervalt binnen ".concat(nbDays, " dagen"); },
        upgradeSubscription: "Upgrade"
    }
};
export default nl;
